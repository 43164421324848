import React, { useEffect } from "react";

import { google } from "@google-cloud/translate/build/protos/protos";
import { signUpWithGoogle } from "../../services/verifiedBackendService/user";
import { googleAuthClientKey } from "../../constants/Enums";

function GoogleAuthButton(props) {
	let interval;
	const onSuccess = (client) => {
		props.setLoading(true);
		signUpWithGoogle(client.credential).then((r) => {
			if (r) {
				props.onSuccess(r);
			} else {
				props.onFail("googleExternalSignUpError");
			}
			props.setLoading(false);
		});
	};
	const initalizeGoogleButton = () => {
		if (window.google) {
			clearInterval(interval);
			window.google.accounts.id.initialize({
				client_id: googleAuthClientKey,
				callback: onSuccess,
			});
			window.google.accounts.id.renderButton(
				document.getElementById("google-sign-up"),
				{
					text: "continue_with",
					shape: "square",
					theme: "outline",
					type: "icon",
					size: "large",
				}
			);

			// Uncomment for Google prompt from bottom.
			//google.accounts.id.prompt();
		}
	};
	useEffect(() => {
		interval = setInterval(initalizeGoogleButton, 100);
	}, []);

	return (
		<div
			className="external-sso-button flex-row jc-c ai-c"
			id="google-sign-up"
		></div>
	);
}

export default GoogleAuthButton;
