import { getToken, setToken, storeItem } from "../../backend/StorageUtils";
import { StorageKeys } from "../../constants/Enums";

const {
	userRoutes,
	walletRoutes,
	signUpRoutes,
} = require("../../constants/Routes");
const axios = require("axios");

export const getuserByUserName = async (userName) => {
	try {
		let response = await axios.get(
			`${userRoutes.getUserByUsername}/${userName}`
		);
		return response.data;
	} catch (err) {
		console.log(err);
		throw err;
	}
};

export const getCredentialsByEmail = async (username) => {
	try {
		let response = await axios.post(userRoutes.getCredentialsByEmail, {
			email: username,
		});
		return response.data;
	} catch (err) {
		console.log(err);
		return {};
	}
};

export const signupLoginOtp = async (data, status) => {
	try {
		console.log("signup login inner data", data);
		let response = await axios.post(walletRoutes.signupLoginOtp, data);
		console.log("responsedata otp", response.data);
		if (status === "signup") {
			setToken(response.data.token);
		}
		return response.data;
	} catch (err) {
		console.log("inenr otp err", err.response.data);
		return err.response.data;
	}
};

export const resendLoginVerificationCode = async (email, recaptchaToken) => {
	try {
		let res = await axios.post(walletRoutes.resendLoginVerificationCode, {
			email,
			reCaptchaToken: recaptchaToken,
		});
		return res.data;
	} catch (error) {
		console.log(error);
		return error.response.data;
	}
};

export const signUpWithGoogle = async (credential) => {
	try {
		let result = await axios.post(
			signUpRoutes.signUpWithGoogle,
			{},
			{
				headers: {
					googleToken: credential,
				},
			}
		);
		let responseJson = result.data;
		const { token, userId } = responseJson;
		setToken(token);
		storeItem(StorageKeys.user_id, userId);
		const userInfo = await getUserInfo(token);
		console.log("user info", userInfo);
		return userInfo;
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const signUpWithLinkedin = async (code) => {
	try {
		let result = await axios.post(signUpRoutes.signUpWithLinkedin, {
			code,
			redirect: `${window.location.origin}/en/linkedinRedir`,
		});
		let responseJson = result.data;
		const { token, userId } = responseJson;
		setToken(token);
		storeItem(StorageKeys.user_id, userId);
		const userInfo = await getUserInfo(token);
		return userInfo;
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const getUserInfo = async (token) => {
	try {
		const res = await axios.get(walletRoutes.user, {
			headers: {
				Authorization: token || getToken(),
			},
		});
		const responseJson = res.data;
		if (!responseJson.success) throw new Error();

		return responseJson;
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const signUpWithFacebook = async (fbUser) => {
	try {
		if ("accessToken" in fbUser) {
			let result = await axios.post(signUpRoutes.signUpWithFacebook, fbUser);
			let responseJson = result.data;
			const { token, userId } = responseJson;
			setToken(token);
			storeItem(StorageKeys.user_id, userId);
			const userInfo = await getUserInfo(token);
			return userInfo;
		} else {
			throw new Error("Facebook Login Failed");
		}
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const verifyLoginMfa = async (code, userId) => {
	try {
		let res = await axios.post(walletRoutes.verifyLoginMFA, {
			code,
			userId,
		});
		const responseJson = res.data;
		if (!responseJson.success) throw new Error();

		setToken(responseJson.token);
		storeItem(StorageKeys.user_id, responseJson.userId);
		const userInfo = await getUserInfo(responseJson.token);
		return userInfo;
	} catch (error) {
		console.log(error);
		return error.response.data;
	}
};

export const switchWallet = async () => {
	try {
		let res = await axios.post(walletRoutes.switchWallet);
		const responseJson = res.data;
		if (!responseJson.success) throw new Error();
		return responseJson;
	} catch (error) {
		console.log(error);
		return error.response.data;
	}
};
