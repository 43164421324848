// Sample Credential No's
export const philipMorrisSubdomain = "philip-morris";
// philip-morris 52252422127528
export const storyIqSubdomain = "storyiq";
// storyiq 39157371000589
export const nationalTrialLawyersSubdomain = "the-national-trial-lawyers";
// the-national-trial-lawyers 06305115959792
export const nationalBlackLawyersSubdomain = "national-black-lawyers";
// national-black-lawyers 27108830874044
export const carahSoftSubdomain = "carahsoft";
// carahsoft 96346057363312
export const openweaverSubdomain = "kandi";
// kandi 18561181049697
export const diverseForceSubdomain = "diverseforce";
// diverseforce 71474715925007

// aa-media-group 87222627453265
