import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./externalAccountItems.scss";
import LinkedInAuthButton from "./LinkedinAuthButton";
import GoogleAuthButton from "./GoogleAuthButton";
import FacebookAuthButton from "./FacebookAuthButton";
import { withLocalize } from "react-localize-redux";
import { useAppDispatch } from "../../store/hooks";
import Notification from "../notification/Notification";
import translations from "../../translations/externalSSO.json";
import { userActions } from "../../store/features/user";
import { useLayoutEffect } from "react";
import mixpanel from "mixpanel-browser";
import { OptimizedMixPanel } from "wallet-elementkit/dist/services";

const ExternalAccountItems = (props) => {
	useLayoutEffect(() => {
		props.addTranslation(translations);
	}, []);
	const optimizedMixpanel = new OptimizedMixPanel(mixpanel);

	const dispatch = useAppDispatch();
	const history = useHistory();

	const onSuccess = (userResponse, type) => {
		dispatch(userActions.setUser(userResponse.user));
		dispatch(userActions.setUserWalletData(userResponse.userWalletData));
		dispatch(userActions.setUserEmails(userResponse.userEmails));
		dispatch(userActions.setLogged(true));
		dispatch(userActions.setIsMailConfirmed(true));
		dispatch(userActions.setAllUserWallets(userResponse.allUserWallets));
		mixpanel.identify(userResponse.user.id.toString());
		mixpanel.people.set({
			$email: userResponse.user.email,
			$name: userResponse.user.name,
			username: userResponse.user.username,
			emails: userResponse.userEmails.map((email) => email.value),
		});
		optimizedMixpanel.track("External Authentication", {
			type,
		});
		if (!history.location.pathname.includes("verify"))
			history.push({
				pathname: `/${
					props.activeLanguage ? props.activeLanguage.code : "en"
				}/profile/${userResponse.user.username}`,
			});
	};

	const onFail = (error) => {
		Notification({
			type: "error",
			text: props.translate(error).toString(),
		});
	};

	return (
		<div id="external-account-items" className="items-row flex-row ai-c jc-c">
			<GoogleAuthButton
				setLoading={props.setLoading}
				onSuccess={(userResp) => onSuccess(userResp, "google")}
				onFail={onFail}
			/>
			<LinkedInAuthButton
				setLoading={props.setLoading}
				onSuccess={(userResp) => onSuccess(userResp, "linkedin")}
				onFail={onFail}
			/>
			<FacebookAuthButton
				setLoading={props.setLoading}
				onSuccess={(userResp) => onSuccess(userResp, "facebook")}
				onFail={onFail}
			/>
		</div>
	);
};

export default withLocalize(ExternalAccountItems);
