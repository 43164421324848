import React, { Suspense, useEffect, useState } from "react";
import { Route, withRouter } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";

import "./styles/app.scss";
import "react-toastify/dist/ReactToastify.css";
import { getMasterConfig } from "wallet-elementkit/dist/constants/master";
import SwitchLocalizeWrapper from "./components/common/SwitchLocalizeWrapper";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import {
	philipMorrisSubdomain,
	storyIqSubdomain,
	carahSoftSubdomain,
	diverseForceSubdomain,
} from "./constants/Subdomains";
import axios from "axios";
import SuspenseLoader from "./components/suspenseLoader/SuspenseLoader";
import { ToastContainer } from "react-toastify";
import Login from "./screens/login/Login";
import VerifyCode from "./screens/verifyCode/VerifyCode";
import Signup from "./screens/signup/Signup";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { selectUser, userActions } from "./store/features/user";
import { getToken } from "./backend/StorageUtils";
import {
	Redirect,
	useHistory,
	useLocation,
} from "react-router-dom/cjs/react-router-dom";
import {
	getUserInfo,
	verifyLoginMfa,
} from "./services/verifiedBackendService/user";
import Mixpanel from "mixpanel-browser";
import { getMixpanelToken } from "./services/utils/mixpanelToken";
import ScrollToTopWrapper from "./components/scrollToTopWrapper/ScrollToTopWrapper";
import { OptimizedMixPanel } from "wallet-elementkit/dist/services";
import QuickClaimProgress from "./screens/quickClaimProgress/QuickClaimProgress";
import { createGlobalStyle } from "styled-components";
import { Subdomains } from "wallet-elementkit/dist/constants";

const defaultMasterConfig = getMasterConfig();
Mixpanel.init(getMixpanelToken());

const Header = React.lazy(() => import("./components/common/header/Header"));
const Footer = React.lazy(() => import("./components/common/Footer"));
const NewVerify = React.lazy(() => import("./screens/verify/NewVerify"));
const Collection = React.lazy(() => import("./screens/collections/Collection"));
const PhilipMorris = React.lazy(() => import("./screens/verify/PhilipMorris"));
const CarahSoft = React.lazy(() =>
	import("./screens/verify/CarahSoft/CarahSoft")
);
const DiverseForce = React.lazy(() =>
	import("./screens/verify/DiverseForce/DiverseForce")
);
const Credentials = React.lazy(() =>
	import("./screens/credentials/Credentials")
);
const Skill = React.lazy(() => import("./screens/skill/Skill"));
const Category = React.lazy(() => import("./screens/category/Category"));
const Landing = React.lazy(() => import("./screens/landing/Landing"));
const Error404 = React.lazy(() => import("./screens/error404/Error404"));
const StoryIQ = React.lazy(() => import("./screens/verify/StoryIQ"));

const StoryIQFooter = React.lazy(() =>
	import("./components/storyIQ/StoryIQFooter")
);
const StoryIQHeader = React.lazy(() =>
	import("./components/storyIQ/StoryIQHeader")
);
const CarahSoftFooter = React.lazy(() =>
	import("./components/carahSoft/CarahSoftFooter")
);
const DiverseForceFooter = React.lazy(() =>
	import("./components/diverseForce/DiverseForceFooter")
);
const FallbackRedirect = React.lazy(() =>
	import("./components/utility/FallbackRedirect")
);
const CarahSoftHeader = React.lazy(() =>
	import("./components/carahSoft/CarahSoftHeader")
);
const PendingCertificate = React.lazy(() =>
	import("./screens/pendingCertificate/pendingCertificate")
);
const NetworkError = React.lazy(() =>
	import("./components/common/NetworkError")
);
const MaintenanceBanner = React.lazy(() =>
	import("./components/common/MaintenanceBanner")
);
const IssuerProfile = React.lazy(() =>
	import("./screens/issuerProfile/IssuerProfile")
);
 
const GlobalStyle = createGlobalStyle`
    body {
        font-family: ${({ fontFamily }) => fontFamily} !important;
    }

	body *
	{
		font-family: ${({ fontFamily }) => fontFamily};
	}
`;


const App = (props) => {
	const [isMobile, setIsMobile] = useState(window.screen.availWidth <= 576);
	const [isLanding, setIsLanding] = useState(
		!window.location.href.split("/")[4]
	);
	const [isPreview, setIsPreview] = useState(
		window.location.pathname.includes("/preview/")
	);
	const [networkError, setNetworkError] = useState(false);
	const [masterConfig, setMasterConfig] = useState(defaultMasterConfig);
	const [activeLanguage, setActiveLanguage] = useState("en");
	const [switchLoading, setSwitchLoading] = useState(true);

	const userMeta = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const history = useHistory();
	const optimizedMixpanel = new OptimizedMixPanel(Mixpanel);

	async function checkAndHandleWalletSwitchOrWalletAuthRedirection() {
		// check url for isSwitch, userId and verificationCode params
		const urlParams = new URLSearchParams(window.location.search);

		// Wallet Switch Params
		const isSwitch = urlParams.get("isSwitch");
		const userId = urlParams.get("userId");
		const verificationCode = urlParams.get("verificationCode");

		// Wallet Auth Redirect Params
		const status = urlParams.get("status");

		console.log("isSwitch", isSwitch);
		console.log("userId", userId);
		console.log("verificationCode", verificationCode);
		console.log("status", status);

		if (isSwitch && userId && verificationCode) {
			console.log("wallet switch active");
			const userData = await verifyLoginMfa(verificationCode, userId);
			console.log("switch verify", userData);
			if (userData != null && userData.success) {
				dispatch(userActions.setUser(userData.user));
				dispatch(userActions.setUserId(userData.user.id));
				dispatch(userActions.setUserWalletData(userData.userWalletData));
				dispatch(userActions.setLogged(true));
				dispatch(userActions.setUserEmails(userData.userEmails));
				dispatch(userActions.setIsMailConfirmed(true));
				dispatch(userActions.setAllUserWallets(userData.allUserWallets));
				setSwitchLoading(false);
				Mixpanel.identify(userData.user.id.toString());
				Mixpanel.people.set({
					$email: userData.user.email,
					$name: userData.user.name,
					username: userData.user.username,
					emails: userData.userEmails.map((email) => email.value),
				});
				history.push({
					pathname: `/${
						props.activeLanguage ? props.activeLanguage.code : "en"
					}/profile/${userData.user.username}`,
				});
			}
		} else if (status && userId && verificationCode) {
			console.log("wallet auth redirection active");
			const userData = await verifyLoginMfa(verificationCode, userId);
			console.log("auth redirection verify", userData);
			if (userData != null && userData.success) {
				dispatch(userActions.setUser(userData.user));
				dispatch(userActions.setUserWalletData(userData.userWalletData));
				dispatch(userActions.setLogged(true));
				dispatch(userActions.setUserEmails(userData.userEmails));
				dispatch(userActions.setIsMailConfirmed(true));
				dispatch(userActions.setAllUserWallets(userData.allUserWallets));
				setSwitchLoading(false);
				history.push({
					pathname: `/${
						props.activeLanguage ? props.activeLanguage.code : "en"
					}/profile/${userData.user.username}`,
				});
			}
		} else {
			setSwitchLoading(false);
		}
	}

	useEffect(() => {
		checkAndHandleWalletSwitchOrWalletAuthRedirection();
		document.body.scrollTop = 0;
		document.body.scrollLeft = 0;
		const resizeListener = (e) => {
			let { screen } = e.target;
			setIsMobile(screen.availWidth <= 576);
		};

		const hrefListener = () => {
			setIsLanding(!window.location.href.split("/")[4]);
		};

		const messageListener = (e) => {
			localStorage.setItem(
				"masterConfig",
				JSON.stringify({ ...getMasterConfig(), ...e.data })
			);
			setMasterConfig({ ...getMasterConfig(), ...e.data });
		};

		window.addEventListener("resize", resizeListener);
		window.addEventListener("popstate", hrefListener);
		window.addEventListener("message", messageListener);

		return () => {
			window.removeEventListener("resize", resizeListener);
			window.removeEventListener("popstate", hrefListener);
			window.removeEventListener("message", messageListener);
		};
	}, []);

	axios.defaults.headers.common["Authorization"] = getToken();

	function checkLoggedIn() {
		return getToken() && userMeta && userMeta.logged;
	}

	function showLogSignPages() {
		return !checkLoggedIn() && !userMeta.isMailConfirmed;
	}

	useEffect(() => {
		const subDomain =
			getMasterConfig().subDomain !== "verified.sertifier.com"
				? `${getMasterConfig().subDomain}.verified.cv`
				: "verified.sertifier.com";
		axios.defaults.headers.common["domain"] = subDomain;

		axios.interceptors.response.use(
			(response) => response,
			(error) => {
				if (!error.config?.suppressNetworkError) {
					if (error.message === "Network Error") {
						setNetworkError(true);
					}
					if (error.response && error.response.status >= 500) {
						setNetworkError(true);
					}
				}

				return Promise.reject(error);
			}
		);
	}, []);

	useEffect(() => {
		try {
			optimizedMixpanel.track("Page Load", {
				page: location.pathname,
				search: location.search,
			});
			document.body.scrollTop = 0;
			document.body.scrollLeft = 0;

			setNetworkError(false);
			let lang = "en";
			lang = location.pathname.split("/")[1];
			setActiveLanguage(lang);
			axios.defaults.headers.common["language"] = lang;

			const token = getToken();
			if (token && (!userMeta.logged || !userMeta.isMailConfirmed)) {
				getUserInfo(token).then((userData) => {
					if (userData !== null && userData.success) {
						dispatch(userActions.setUser(userData.user));
						dispatch(userActions.setUserId(userData.user.id));
						dispatch(userActions.setUserWalletData(userData.userWalletData));
						dispatch(userActions.setUserEmails(userData.userEmails));
						dispatch(userActions.setLogged(true));
						dispatch(userActions.setAllUserWallets(userData.allUserWallets));
						Mixpanel.identify(userData.user.id.toString());
						Mixpanel.people.set({
							$email: userData.user.email,
							$name: userData.user.name,
							username: userData.user.username,
							emails: userData.userEmails.map((email) => email.value),
						});
						// TODO Will we use it ?(is mail confirmed)
						// if (new UserUtils(userData.user).isEmailConfirmed)
						// 	dispatch(userActions.setIsMailConfirmed(true));
					}
				});
			}
		} catch (e) {
			// console.log(e);
		}
	}, [location]);

	return (
		<div className="app">
			<GlobalStyle
				fontFamily={
					getMasterConfig().subDomain === Subdomains.bitdefender
						? `"IBM Plex Sans", sans-serif`
						: undefined
				}
			/>
			<LocalizeProvider>
				{switchLoading ? (
					<SuspenseLoader />
				) : (
					<Suspense fallback={<SuspenseLoader />}>
						<ScrollToTopWrapper />
						<MaintenanceBanner />
						{defaultMasterConfig &&
						defaultMasterConfig.subDomain === storyIqSubdomain ? (
							<StoryIQHeader isMobile={isMobile} />
						) : defaultMasterConfig &&
						  defaultMasterConfig.subDomain === carahSoftSubdomain ? (
							<CarahSoftHeader isMobile={isMobile} />
						) : (
							<Header
								isMobile={isMobile}
								location={props.location}
								isPreview={isPreview}
								config={masterConfig}
							/>
						)}

						<SwitchLocalizeWrapper>
							{networkError && <NetworkError isMobile={isMobile} />}
							<Route
								exact
								path="/"
								render={(props) => (
									<Landing
										{...props}
										isMobile={isMobile}
										config={masterConfig}
									/>
								)}
							/>
							<Route
								path="/(verify|certificate)/:credno"
								render={(p) => {
									return defaultMasterConfig &&
										defaultMasterConfig.subDomain === philipMorrisSubdomain ? (
										<PhilipMorris
											isMobile={isMobile}
											initCredNo={p.match.params.credno}
											{...p}
										/>
									) : defaultMasterConfig &&
									  defaultMasterConfig.subDomain === storyIqSubdomain ? (
										<StoryIQ
											isMobile={isMobile}
											initCredNo={p.match.params.credno}
											{...p}
										/>
									) : defaultMasterConfig &&
									  defaultMasterConfig.subDomain === carahSoftSubdomain ? (
										<CarahSoft
											isMobile={isMobile}
											initCredNo={p.match.params.credno}
											{...p}
										/>
									) : defaultMasterConfig &&
									  defaultMasterConfig.subDomain === diverseForceSubdomain ? (
										<DiverseForce
											isMobile={isMobile}
											initCredNo={p.match.params.credno}
											config={masterConfig}
											{...p}
										/>
									) : (
										<NewVerify
											isMobile={isMobile}
											initCredNo={p.match.params.credno}
											config={masterConfig}
											{...p}
										/>
									);
								}}
							/>
							<Route
								path="/preview/:deliveryId"
								render={(p) => {
									return defaultMasterConfig &&
										defaultMasterConfig.subDomain === philipMorrisSubdomain ? (
										<PhilipMorris
											isMobile={isMobile}
											isPreview={true}
											deliveryId={p.match.params.deliveryId}
											{...p}
										/>
									) : defaultMasterConfig &&
									  defaultMasterConfig.subDomain === storyIqSubdomain ? (
										<StoryIQ
											isMobile={isMobile}
											isPreview={true}
											deliveryId={p.match.params.deliveryId}
											{...p}
										/>
									) : defaultMasterConfig &&
									  defaultMasterConfig.subDomain === carahSoftSubdomain ? (
										<CarahSoft
											isMobile={isMobile}
											isPreview={true}
											deliveryId={p.match.params.deliveryId}
											{...p}
										/>
									) : (
										<NewVerify
											isMobile={isMobile}
											isPreview={true}
											deliveryId={p.match.params.deliveryId}
											config={masterConfig}
											{...p}
										/>
									);
								}}
							/>
							<Route exact path="/linkedinRedir" component={LinkedInCallback} />

							<Route
								path="/profile/:username"
								render={(p) => {
									return (
										<Credentials
											isMobile={isMobile}
											username={p.match.params.username}
										/>
									);
								}}
							/>
							<Route
								path="/credentials/:username/:categoryNo"
								render={(p) => {
									return <Category isMobile={isMobile} />;
								}}
							/>
							<Redirect
								from={`/${activeLanguage}/credentials/:username`}
								to={`/${activeLanguage}/profile/:username`}
							/>
							<Route
								exact
								path="/category/:username/:categoryNo"
								render={(p) => <Category isMobile={isMobile} campaign={true} />}
							/>
							<Route
								path="/pending-certificate"
								render={(p) => {
									return <PendingCertificate isMobile={isMobile} />;
								}}
							/>
							<Route
								path="/collection/:id"
								render={(props) => (
									<Collection
										id={props.match.params.id}
										{...props}
										isMobile={isMobile}
									/>
								)}
							/>
							<Route
								exact
								path="/skill/:skillId"
								render={(p) => <Skill isMobile={isMobile} {...p} />}
							/>
							<Route
								exact
								path="/quick-claim"
								render={(p) => (
									<QuickClaimProgress isMobile={isMobile} {...p} />
								)}
							/>
							{showLogSignPages() && (
								<Route
									exact
									path="/login"
									render={(p) => <Login isMobile={isMobile} {...p} />}
								/>
							)}
							{showLogSignPages() && (
								<Route
									exact
									path="/signup"
									render={(p) => <Signup isMobile={isMobile} {...p} />}
								/>
							)}
							<Route
								exact
								path="/verify-code"
								render={(p) => <VerifyCode isMobile={isMobile} {...p} />}
							/>
							<Route
								exact
								path="/issuer-profile/:issuerId"
								render={(p) => <IssuerProfile isMobile={isMobile} {...p} />}
							/>
							<FallbackRedirect redirectPath={"/"} />
							<Route path="*" component={Error404} />
						</SwitchLocalizeWrapper>
						{defaultMasterConfig &&
						defaultMasterConfig.subDomain === storyIqSubdomain ? (
							<StoryIQFooter isMobile={isMobile} />
						) : defaultMasterConfig &&
						  defaultMasterConfig.subDomain === carahSoftSubdomain ? (
							<CarahSoftFooter isMobile={isMobile} />
						) : defaultMasterConfig &&
						  defaultMasterConfig.subDomain === diverseForceSubdomain ? (
							<DiverseForceFooter isMobile={isMobile} config={masterConfig} />
						) : (
							<Footer isMobile={isMobile} config={masterConfig} />
						)}
						<ToastContainer />
					</Suspense>
				)}
			</LocalizeProvider>
		</div>
	);
};

export default withRouter(App);
