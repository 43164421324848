import React from "react";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { ReactComponent as LinkedinLogo } from "../../assets/images/socials/linkedin-black.svg";
import { signUpWithLinkedin } from "../../services/verifiedBackendService/user";

const LinkedInAuthButton = (props) => {
	const onSuccess = async () => {
		props.setLoading(true);
		signUpWithLinkedin().then((r) => {
			if (r) {
				props.onSuccess(r);
			} else {
				props.onFail("linkedinExternalSignUpError");
			}
			props.setLoading(false);
		});
	};
	return (
		<LinkedIn
			clientId="775jdov7xhw33h"
			redirectUri={`${window.location.origin}/en/linkedinRedir`}
			onSuccess={onSuccess}
			scope="r_emailaddress r_liteprofile w_member_social"
			closePopupMessage="Close Pop Up"
			onError={(error) => {
				console.log(error);
			}}
		>
			{({ linkedInLogin }) => (
				<div
					className="external-sso-button external-sso-button-border flex-row jc-c ai-c"
					onClick={linkedInLogin}
				>
					<LinkedinLogo className="social-icon" fill="rgb(51,117,178)" />
				</div>
			)}
		</LinkedIn>
	);
	/* return (
        <div className="external-sso-button flex-row jc-c ai-c">
            <img src={Linkedin} alt="Linkedin Logo" />
        </div>
    ); */
};
export default LinkedInAuthButton;
