import React, { useState } from "react";
import Facebook from "../../assets/images/socials/facebook-colored.png";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { signUpWithFacebook } from "../../services/verifiedBackendService/user";

const FacebookAuthButton = (props) => {
	let [isClicked, setClicked] = useState(false);
	const onSuccess = (userInfo) => {
		props.setLoading(true);
		signUpWithFacebook(userInfo).then((r) => {
			if (r) {
				props.onSuccess(r);
			} else {
				props.onFail("facebookExternalSignUpError");
			}
			props.setLoading(false);
		});
	};

	return (
		<>
			{isClicked ? (
				<FacebookLogin
					appId="878231249971057"
					autoLoad={true}
					fields="name,email,picture.type(large)"
					cssClass="my-facebook-button-class"
					icon="fa-facebook"
					callback={onSuccess}
					render={(renderProps: any) => (
						<div
							key="fb-login-btn"
							role="button"
							onClick={() => setClicked((v) => !v)}
							className="external-sso-button external-sso-button-border flex-row jc-c ai-c"
						>
							<img
								src={Facebook}
								style={{ width: 19, height: 19 }}
								className="social-icon"
								alt="Facebook Logo"
							/>
						</div>
					)}
					scope="public_profile,email"
					returnScopes={true}
					redirectUri={`${window.location.origin}/en/facebook`}
					reAuthenticate={true}
					xfbml={true}
					cookie={true}
				/>
			) : (
				<div
					key="fb-login-btn"
					role="button"
					onClick={() => setClicked((v) => !v)}
					className="external-sso-button external-sso-button-border flex-row jc-c ai-c"
				>
					<img
						src={Facebook}
						style={{ width: 19, height: 19 }}
						className="social-icon"
						alt="Facebook Logo"
					/>
				</div>
			)}
		</>
	);
};

export default FacebookAuthButton;
