const mainAddress = "https://api.verified.cv";
// const mainAddress = "http://localhost:3000";
// const mainAddress = "http://localhost:3002"; // beta-verified
// const mainAddress = "http://192.168.1.33:3002";
// const mainAddress = "http://localhost:3001";

const sertifierMainAddress = "https://b2b.sertifier.com";
// const sertifierMainAddress = "http://localhost:5000";

const apiAddress = mainAddress + "/v1";
const apiAddressV2 = mainAddress + "/v2";
const apiAddressV3 = mainAddress + "/v3";

// Big Routes
const search = apiAddressV2 + "/search";
const searchV3 = apiAddressV3 + "/search";
const users = apiAddressV2 + "/users";
const usersV3 = apiAddressV3 + "/users";
const errLog = apiAddressV2 + "/errorlog";
const certificates = apiAddress + "/certificates";

// Inner Routes Search
const searchCredential = search + "/credential";
const credentialPreview = search + "/credentialPreview";
const searchUser = search + "/userPublic";
const getCredentialsByEmail = searchV3 + "/getAllCertificatesAnonym";
const getSingleUser = searchV3 + "/singleUser";

// Inner Routes Users
const getUserByUsername = users + "/getUserByUserNameAnon";
const logActionEvent = usersV3 + "/logActionEventAnonym";

//Inner Routes Certificates
const certificatesShareEvent = certificates + "/shareEvent";
const certificatesShareEventAnon = certificates + "/shareEventAnon";
const addToLinkedinAnon = certificates + "/addToLinkedInAnon";
const pdfDownloadAnon = apiAddress + "/certificates/downloadPdfEvent/anon";

// Inner Routes Sertifier
const generatePdfLink = sertifierMainAddress + "/Recipient/GeneratePDFLink";
const getMasterConfigRoute = sertifierMainAddress + "/WhiteLabel/GetConfigs";
const searchUserFromSertifier =
	sertifierMainAddress + "/whiteLabel/SearchUsers";

// IMS
const ims = apiAddressV2 + "/ims";
const imsImage = ims + "/image";

//Ad Log
const createAdLog = apiAddressV3 + "/marketing/log";

// Certificate
const evidenceParse = apiAddressV3 + "/certificate/parseEvidences";
const parsePreviewEvidences =
	apiAddressV3 + "/certificate/parsePreviewEvidences";
const verifyCredential = apiAddressV3 + "/certificate/verifyCredential";
const getMemberLink = apiAddressV3 + "/certificate/getMemberLink";

// White Label
const getMasterConfig = apiAddressV3 + "/whiteLabel/getMasterConfig";
const generatePdfLinkVerified = apiAddressV3 + "/whitelabel/GeneratePDFLink";

//QR Code
const generateQrWalletImg = apiAddressV3 + "/certificate/downloadQRWalletImage";

// Linkedin
const linkedinProxy = apiAddressV3 + "/linkedinProxy";
const sharePostLinkedin = apiAddressV3 + "/sharePostLinkedin";
const linkedInAccessToken = apiAddressV3 + "/wallet/getLinkedInAccessToken";

// Issuer Profile
const getIssuer = apiAddressV3 + "/issuer/get";
const getCampaigns = apiAddressV3 + "/issuer/getCampaigns";
const getCollections = apiAddressV3 + "/issuer/getCollections";
const getRecipients = apiAddressV3 + "/issuer/getRecipients";

// Wallet Routes
const signupLoginOtp = apiAddressV3 + "/wallet/signupLoginOtp";
const resendLoginVerificationCode =
	apiAddressV3 + "/wallet/resendLoginVerificationCode";
const user = apiAddressV3 + "/wallet/user";
const switchWallet = apiAddressV3 + "/wallet/switchWallet";
const verifyLoginMFA = apiAddressV3 + "/wallet/verifyLoginMFA";

// Sign Up
const signUpWithGoogle = apiAddressV3 + "/wallet/googleSignIn";
const signUpWithLinkedin = apiAddressV3 + "/wallet/linkedInSignIn";
const signUpWithFacebook = apiAddressV3 + "/wallet/facebookSignIn";

// Profile
const walletProfile = apiAddressV3 + "/wallet/profile";

// Email Verification
const verifyEmail = apiAddressV3 + "/wallet/verifyEmail";
const resendEmailVerification =
	apiAddressV3 + "/wallet/resendEmailVerificationNoRecaptcha";

// Skill
const getSkillPage = apiAddressV3 + "/wallet/getSkillPage";

module.exports.searchRoutes = Object.freeze({
	searchCredential,
	credentialPreview,
	searchUser,
	getSingleUser,
});

module.exports.userRoutes = Object.freeze({
	getUserByUsername,
	getCredentialsByEmail,
});

module.exports.errLog = Object.freeze({
	errLog,
});

module.exports.linkedinRoutes = Object.freeze({
	linkedinProxy,
	sharePostLinkedin,
	linkedInAccessToken,
});

module.exports.certificateEventRoutes = Object.freeze({
	certificatesShareEvent,
	certificatesShareEventAnon,
	addToLinkedinAnon,
	pdfDownloadAnon,
	logActionEvent,
});

module.exports.sertifierRoutes = Object.freeze({
	generatePdfLink,
	getMasterConfigRoute,
	searchUserFromSertifier,
});

module.exports.imsRoutes = Object.freeze({
	ims,
	imsImage,
});

module.exports.logRoutes = Object.freeze({
	createAdLog,
});

// Certificate Routes
module.exports.certificateRoutes = Object.freeze({
	evidenceParse,
	parsePreviewEvidences,
	verifyCredential,
	getMemberLink,
});

// White Label Routes
module.exports.whiteLabelRoutes = Object.freeze({
	getMasterConfig,
	generatePdfLinkVerified,
});

module.exports.qrDownloadRoutes = Object.freeze({
	generateQrWalletImg,
});

module.exports.issuerRoutes = Object.freeze({
	getIssuer,
	getCampaigns,
	getCollections,
	getRecipients,
});

module.exports.walletRoutes = Object.freeze({
	signupLoginOtp,
	resendLoginVerificationCode,
	user,
	verifyLoginMFA,
	switchWallet,
	getSkillPage,
});

module.exports.signUpRoutes = Object.freeze({
	signUpWithGoogle,
	signUpWithLinkedin,
	signUpWithFacebook,
});

module.exports.profileRoutes = Object.freeze({
	walletProfile,
});

module.exports.emailVerificationRoutes = Object.freeze({
	resendEmailVerification,
	verifyEmail,
});
